<template>
  <div class="layout">
    <div class="introduction-background">
      <img src="../../assets/light.svg" />
    </div>

    <div class="introduction">
      <div class="introduction-header">
        <div class="introduction-logo">
          <Logo />
        </div>
      </div>
      <div class="introduction-content">

        <div class="introduction-illustration">
          <video
            v-if="!$device.isSafari && !$device.isIphone && !$device.isTablet"
            muted="muted"
            autoplay="autoplay"
            loop="loop"
            src="/videos/introduction.webm"
          />
          <img v-else src="/images/introduction.png">
        </div>
        
        <div v-if="step === 0" class="introduction-text">
          <transition name="t-fader-absolute-top--delayed">
            <message-toast
              :text="text"
              look="white"
              class="introduction-message"
              cta="Continuer"
              @next="step++"
              :isEnd="true"
            />
            </transition>
        </div>
        <div v-if="step === 1" class="introduction-text">
          <transition name="t-fader-absolute-top--delayed">
            <message-toast
              :text="text2"
              look="white"
              class="introduction-message"
              cta="Page d'accueil"
              @next="$router.push({name: 'introduction'})"
              :isEnd="true"
            />
          </transition>
        </div>
        
      </div>
    </div>
    <transition name="t-fader">
      <video-player
        v-if="isTransitionEnd"
        :forcePlay="true"
        :loop="false"
        @ended="nextLevel"
        :src="data.video[0].url"
        :isStoppable="false"
        class="u-wrapper-panel u-fit-cover"
      />
    </transition>
  </div>
</template>

<script>
import { Audio, audio } from '@/utils/Audio'

import Logo from "../elements/Logo.vue"
import MessageToast from "../elements/MessageToast.vue"
import VideoPlayer from "../common/players/VideoPlayer"

const text = `
<div class="end-text">
  <div class="u-marg-t-sm">Vous l’avez découvert avec Malik : la nouvelle app conserve toutes les fonctionnalités existantes et s’enrichit de nouvelles pour devenir le compagnon du quotidien de nos clients. Elle permet maintenant de s’informer, réserver et payer son trajet depuis son smartphone grâce à une app tout-en-un qui propose tous les modes de transport disponibles à un instant T : c’est pratique le MaaS !</div>
  <div class="bold u-marg-t-sm">Pour télécharger les applications officielles :</div>
  <ul>
    <li>
      <div>Ile-de-France Mobilités</div>
      <ul>
        <li>
          <div><a class="link" target="_blank" href="https://apps.apple.com/fr/app/%C3%AEle-de-france-mobilit%C3%A9s/id484527651\">Télécharger l’application Île-de-France Mobilités dans l’app Store</a></div>
        </li>
        <li>
          <div><a class="link" target="_blank" href="https://play.google.com/store/apps/details?id=com.applidium.vianavigo&hl=fr">Télécharger l’application Île-de-France Mobilités sur Google Play</a></div>
        </li>
      </ul>
    </li>
    <li>
      <div>Bonjour RATP</div>
      <ul>
        <li>
          <div><a class="link" target="_blank" href="https://apps.apple.com/fr/app/bonjour-ratp/id507107090">Bonjour RATP dans l’App Store (apple.com)</a></div>
        </li>
        <li>
          <div><a class="link" target="_blank" href="https://play.google.com/store/apps/details?id=com.fabernovel.ratp&hl=fr&gl=US">Télécharger l’application Bonjour RATP sur Google Play</a></div>
        </li>
      </ul>
    </li>
  </ul>
</div>`

const text2 = `
<div class="end-text">
  <div class="bold u-marg-t-sm">Pour suivre l’app Bonjour RATP sur les réseaux sociaux :</div>
  <ul>
    <li>
      <div><a class="link" target="_blank" href="https://www.instagram.com/bonjourratp/">Instagram</a></div>
    </li>
    <li>
      <div><a class="link" target="_blank" href="https://m.facebook.com/Bonjour-RATP-106822198426551/">Facebook</a></div>
    </li>
  </ul>

  <div class="u-marg-t-sm">Pour en savoir plus sur l’achat des titres de transports et vérifier les smartphones compatibles, <a class="link" target="_blank" href="https://www.ratp.fr/achetez-vos-titres-de-transports-par-telephone">consultez cette page</a>.</div>
  <div class="u-marg-t-sm">Vidéo réalisée par l’Académie RATP du service du département CML.</div>
  <div class="u-row u-middle u-evenly u-marg-t-md">
    <img src="/images/img-2.png">
    <img src="/images/img-1.jpg">
  </div>
</div>`


export default {
  name: "Introduction",

  components: {
    Logo,
    MessageToast,
    VideoPlayer
  },

  data() {
    return {
      currentMessage: -1,
      isTransitionEnd: false,
      text: text,
      text2: text2,
      step: 0
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/homepage']
    },

    firstSection () {
      const sections = this.$store.getters['data/sections']
      if (sections.length) {
        return this.$store.getters['data/sections'][0]
      }
      return null
    }
  },

  created () {
    this.currentMessage++
  },

  methods: {
    nextMessage () {
      audio.src = this.$store.state.global.sounds.nextLevel
      audio.play()

      if (this.currentMessage === this.data.multipleTexts.length - 1) {
        // this.nextLevel()
        this.isTransitionEnd = true
        this.currentMessage++
      }else{
        this.currentMessage++
      }
    },
    nextLevel () {
      this.$router.push({name: 'chapters', params: {section: this.firstSection.slug}})
    }
  }
};
</script>

<style lang="stylus">
.end-text
  font-weight 400
  font-size 16px
  line-height normal

  .bold
    font-weight 800
  .link
    text-decoration underline
  
  ul
    list-style-type disc
    padding-left 2em
  
  img 
    width 4em
    height auto
</style>

<style lang="stylus" scoped>

.layout
  position fixed
  width 100%
  height 100%
  // background #0e0284
  background url('/images/blue.jpg') repeat

.introduction-background
  position absolute
  // z-index -1
  width 100%
  height 100%
  pointer-events none

  img
    object-fit cover
    width 100%
    height 100%

.introduction
  display flex
  flex-direction column
  align-items center
  justify-content space-between
  height 100%

.introduction-content
  display flex
  flex 1
  width: 100%;
  justify-content: center;
@media screen and (max-width: 1200px)
  .introduction-content
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

.introduction-logo
  margin-top: 76px;
@media screen and (max-width: 1200px)
  .introduction-logo
    margin-top: 46px;
@media screen and (max-width: 800px)
  .introduction-logo
    margin-top: 43px;

.introduction-text
  position relative
  max-width 780px
  margin 82px 40px
@media screen and (max-width: 1200px)
  .introduction-text
    max-width: 600px;
    margin: 32px 40px 12px;
    transform: translate(0, -50px);
    bottom: 0;
    z-index 10
@media screen and (max-width: 800px)
  .introduction-text
    position: absolute;
    transform: translate(0, 0);
    max-width: 450px;
    margin: 22px 20px 36px;
    display: flex;
    flex-direction: column-reverse;
    
.introduction-message
  width 100%

.introduction-illustration
  display flex
  justify-content: flex-end;
  position: relative;
  z-index: -1;
  flex: 0 0 30%;
  video, img
    align-self: flex-end;
    position: fixed;
    bottom: 0;
    width: 40%;
    height: auto;
    max-width: 640px;
@media screen and (max-width: 1620px)
  .introduction-illustration
    video, img
      left 0
@media screen and (max-width: 1200px)
  .introduction-illustration
    width 290px
    video, img
      position: relative;
      bottom: inherit;
      width 100%
      height auto
@media screen and (max-width: 800px)
  .introduction-illustration
    width 230px
</style>
