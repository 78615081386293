import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {

  getHomepage () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query homepage {
            homepage: entry(site: "${site}", type: "sthomepage") {
              ... on sthomepage_sthomepage_Entry {
                id
                title
                image {
                  id
                  url
                }
                multipleTexts {
                  ... on multipleTexts_multipleTexts_BlockType {
                    id
                    text
                  }
                }
                video {
                  url
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.homepage

        resolve(result)
        storage.setItem( `fetchHomepage_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchHomepage_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getEndpage () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query endpage {
            endpage: entry(site: "${site}", type: "stendpage") {
              ... on stendpage_stendpage_Entry {
                id
                wysiwyg
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.endpage

        resolve(result)
        storage.setItem( `fetchEndpage_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchEndpage_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getSection () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query sections {
            sections: entries(site: "ratpBonjour", type: "stKeypoint") {
              ... on stKeypoint_stKeypoint_Entry {
                id
                slug
                title
                multipleTexts {
                  ... on multipleTexts_multipleTexts_BlockType {
                    id
                    text
                  }
                }
                video {
                  url
                }
                image {
                  id
                  url
                }
                questions: stQuestions {
                  ... on stQuestions_questions_BlockType {
                    id
                    answers {
                      label
                      value
                    }
                    title: titre
                    subtitle: sousTitre
                    textWin
                  }
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.sections

        resolve(result)
        storage.setItem( `fetchSections_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchSections_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()